/**
 *
 * @param anchorSelector
 * @param activeClass
 */
const addActiveLinkClass = ({ anchorSelector = 'a', activeClass = 'active' }) => {
  const anchors = document.querySelectorAll(anchorSelector);
  if (!anchors) return;

  const current = window.location.pathname;

  for (let i = 0; i < anchors.length; i += 1) {
    if (anchors[i].getAttribute('href') === current) {
      anchors[i].classList.add(activeClass);
    }
  }
};

export default addActiveLinkClass;
