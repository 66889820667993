function FixedHeader() {
  const mainHeader = document.querySelector('.Header');
  const isOrderPage = document.querySelector('.OrderPage');
  const pageYOffset = window.pageYOffset || document.documentElement.scrollTop;
  let offset = 100;

  if (!mainHeader || isOrderPage) return false;

  if (window.innerWidth <= 860) {
    offset = 0;
  }

  if (pageYOffset > offset) {
    mainHeader.classList.add('Header--fixed');
  } else {
    mainHeader.classList.remove('Header--fixed');
  }
}

export default FixedHeader;
