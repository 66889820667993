import './style.scss';
import addActiveLinkClass from '../../../utils/addActiveLinkClass';
import FixedHeader from './FixHeader';

function ToggleMenu() {
  const header = document.querySelector('.Header');
  const burger = header.querySelector('.Header__burger');
  const menu = document.querySelector('.Header__menu');

  this.state = {
    isOpen: false,
  };

  this.open = () => {
    header.classList.add('Header--open');
    menu.classList.add('Header__menu--open');
    burger.classList.add('Header__burger--active');
    this.state.isOpen = true;
  };

  this.hide = () => {
    if (!this.state.isOpen) return;

    this.state.isOpen = false;
    header.classList.remove('Header--open');
    menu.classList.remove('Header__menu--open');
    burger.classList.remove('Header__burger--active');
  };

  burger.addEventListener('click', () => {
    if (this.state.isOpen) {
      this.hide();
    } else {
      this.open();
    }
  });
}

window.addEventListener('DOMContentLoaded', () => {
  new ToggleMenu(); /* eslint-disable-line */

  // Add active class
  addActiveLinkClass({
    anchorSelector: '.Header__menu-list-item a',
  });
});

document.addEventListener('scroll', () => {
  FixedHeader();
});

function OpenSubmenu() {
  const HeaderSubmenu = document.querySelector('.Header-submenu');
  const HeaderSubmenuTip = document.querySelector('.Header-submenu__tip');

  if (HeaderSubmenu && HeaderSubmenuTip) {
    HeaderSubmenuTip.addEventListener('click', () => {
      HeaderSubmenu.classList.toggle('Header-submenu--open');
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (window.matchMedia('(max-width: 1023px)').matches) {
    OpenSubmenu();
  }
});
